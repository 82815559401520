import { VERSION } from "./version";
// Toggle between http and https depending if ssl key is available
const defaultProtocol = 'https';

//Options: dashboard, node, group, zone, inventory, map, power, tilt, airQuality, footfall, weather, admin, search, help, settings, notifications, user
const menuItems = ['dashboard', 'map', 'node', 'group', 'zone', 'admin', 'notifications', 'user', 'help'];

const cardOverview = ['offlineNum', 'onNum', 'offNum'];
const cardTypes = ['wattsLine', 'wattsLoad', 'currentLine', 'currentLoad',
    'voltageLine', 'voltageLoad', 'temperature', 'pm2.5', 'nitrogen', 'humidity', 'ozone'];
const cardNodes = ['ALL'];

export const build = {
    DEFAULT_PROTOCOL: defaultProtocol,
    MENU_ITEMS: menuItems,

    // API_HOST: defaultProtocol + '://ubiapi-app.ubicquia.com/api/',
    API_HOST: defaultProtocol + '://api.config.subpanel.dev.ubicquia.com/api/',
    MQ_HOST: 'http://ubiapi-control.ubicquia.com:5000',
    SITE_URL: 'https://config.subpanel.dev.ubicquia.com/',
    LOGO: 'logo',
	HEADER_LOGO: 'ubivu-logo-dark',
	ICON: 'ubiIcon',

    // Options: en (English), kz (Russian), es (Spanish), pt (Portuguese)
    DEFAULT_LANG: 'en',

    CHART_URL: 'http://ubidemo.ubicquia.com:8080/',
    FOOTFALL_URL: 'https://thinksmarter.io/uiv3/ui.php?token=4f232b6814818a084068eb64652fe58b',
    MAP_URL: defaultProtocol + '://ubitest1.ubicquia.com:8080/lightsmap/map/gmapplotZone.php',
    MAP_KEY: 'AIzaSyAnBaEi_Lv4XcldCA8x0FC_4IPrkRwUuYg',

    OVA_UPDATE: 'http://upd.ubicquia.us',

    CARD_OVERVIEW: cardOverview,
    CARD_TYPES: cardTypes,
    CARD_NODES: cardNodes,
    SECRET_KEY: '0123456789abcdef0123456789abcdef',
    SECRET_IV: 'abcdef9876543210abcdef9876543210',
        //  Version
    VERSION: VERSION.CURRENT_VERSION,
    SUB_VERSION: VERSION.CURRENT_SUB_VERSION,
};
